const bodyElem = document.querySelector('body');

const headerElem = bodyElem.querySelector('.header');
const toggleElem = headerElem.querySelector('.header__toggle');
const navElem = headerElem.querySelector('.nav');

toggleElem.addEventListener('click', () => {
	toggleElem.classList.toggle('header__toggle--active');
	navElem.classList.toggle('nav--active');
});

if(window.outerWidth > 1100) {
	window.addEventListener('scroll', () => {
		const dekstopLogo = headerElem.querySelector('.header__logo--desktop');

		if(window.scrollY >= 120 && !headerElem.classList.contains('header--sticky')) {
			bodyElem.classList.add('sticky');

			headerElem.classList.add('header--sticky');
			navElem.classList.add('nav--sticky');

			dekstopLogo.querySelector('use').setAttribute('xlink:href', '/wp-content/themes/mvosadvies/public/icons.svg#mvosadvies-icon');
		} else if(window.scrollY < 120) {
			bodyElem.classList.remove('sticky');

			headerElem.classList.remove('header--sticky');
			navElem.classList.remove('nav--sticky');

			dekstopLogo.querySelector('use').setAttribute('xlink:href', '/wp-content/themes/mvosadvies/public/icons.svg#mvosadvies-logo');
		}
	});
} else if(window.outerWidth < 1100) {
	const toggleElem = document.querySelector('.js-nav-toggle');

	toggleElem.addEventListener('click', (event) => {
		event.preventDefault();

		toggleElem.classList.toggle('nav__icon--active');

		const submenu = toggleElem.parentElement.nextElementSibling;
		submenu.classList.toggle('sub-menu--active');
	});
}

