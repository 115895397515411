export default class Fader {
	constructor(parent, elems, interval) {
		this.parent = parent;
		this.elems = elems;

		this.index = 0;
		this.intervalTime = interval;

		this.init = this.init.bind(this);
		this.createInterval = this.createInterval.bind(this);
		this.nextSlide = this.nextSlide.bind(this);

		this.init();
	}

	init() {
		this.elems[0].classList.add('slider__item--active');
		this.createInterval();
	}

	createInterval() {
		clearInterval(this.sliderInterval);
		this.sliderInterval = setInterval(this.nextSlide.bind(this), this.intervalTime);
	}

	nextSlide() {
		this.elems[this.index].classList.remove('slider__item--active');

		this.index++;
		if(this.index >= this.elems.length) {
			this.index = 0;
		}

		this.elems[this.index].classList.add('slider__item--active');
	}
}
